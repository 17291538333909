import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import styled from "styled-components"
import Socials from "../components/socials"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const postUrl =
      this.props.data.site.siteMetadata.siteUrl + this.props.location.pathname

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <h1>{post.frontmatter.title}</h1>
        <div
          style={{
            width: "100%",
            height: "1px",
            marginTop: rhythm(1.25),
            background: "#000",
            opacity: 0.1,
          }}
        ></div>
        <div
          style={{
            display: `flex`,
            flexFlow: `row nowrap`,
            alignItems: `center`,
            justifyContent: `space-between`,
            marginBottom: rhythm(2),
            marginTop: rhythm(1 / 2),
          }}
        >
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: 0,
            }}
          >
            {/* <span
              style={{
                marginRight: 16,
                fontWeight: 700,
              }}
            >
              РАЗРАБОТКА
            </span>{" "} */}
            <span style={{ opacity: 0.7 }}>{post.frontmatter.date}</span>
            {/* ・{" "} <span style={{ opacity: 0.7 }}>5 минут</span> */}
          </p>
          <Socials size={20} url={postUrl} title={post.frontmatter.title} />
        </div>

        <div className="content">
          <MDXRenderer>{post.body}</MDXRenderer>
        </div>
        {/* <Tags>
          <TagsItem>Cypress</TagsItem>
          <TagsItem>OAuth</TagsItem>
          <TagsItem>Angular</TagsItem>
        </Tags> */}

        <div style={{ width: `fit-content`, margin: `${rhythm(2)} auto 0` }}>
          <Socials size={30} url={postUrl} title={post.frontmatter.title} />
        </div>

        <div
          style={{
            margin: `${rhythm(2)} 0`,
          }}
        >
          <Bio />
        </div>

        <StyledUl>
          <StyledLi>
            {previous && (
              <Link to={`blog${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </StyledLi>
          <StyledLi>
            {next && (
              <Link to={`blog${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </StyledLi>
        </StyledUl>

        {/* <h3>Похожие посты</h3> */}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "DD MMM YYYY", locale: "ru-RU")
        description
      }
    }
  }
`

const Tags = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: ${rhythm(2)} 0 ${rhythm(1 / 2)};
`

const TagsItem = styled.div`
  padding: 0.2em 0.4em;
  font-size: 0.875rem;
  background: #f1f1f1;
  border-radius: 3px;
  cursor: pointer;

  :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-left: 0;
`

const StyledLi = styled.li`
  max-width: 49%;

  @media all and (max-width: 768px) {
    max-width: none;
  }
`
