/**
 * Component with social share buttons
 */

import React from "react"
import styled from "styled-components"
import { SocialIcon } from "react-social-icons"

class Socials extends React.Component {
  render() {
    return (
      <div>
        <StyledSocialIcon
          network="vk"
          style={{ height: this.props.size, width: this.props.size }}
          url={`https://vk.com/share.php?url=${this.props.url}&title=${this.props.title}`}
          target="_blank"
          bgColor="#cccccc"
          title="Поделиться ВКонтакте"
        />
        <StyledSocialIcon
          network="facebook"
          style={{ height: this.props.size, width: this.props.size }}
          url={`https://www.facebook.com/sharer/sharer.php?u=${this.props.url}&t=${this.props.title}`}
          target="_blank"
          bgColor="#cccccc"
          title="Поделиться на Facebook"
        />
        <StyledSocialIcon
          network="twitter"
          style={{ height: this.props.size, width: this.props.size }}
          url={`https://twitter.com/share?url=${this.props.url}&via=bzvyagintsev&text=${this.props.title}`}
          target="_blank"
          bgColor="#cccccc"
          title="Поделиться в twitter"
        />
      </div>
    )
  }
}

export default Socials

const StyledSocialIcon = styled(SocialIcon)`
  box-shadow: none;
  margin-right: 4px;
  transition: all 0.2s;

  &:hover {
    opacity: 0.7;
  }
`